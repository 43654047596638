<template>
    <Modal ref="modal" :title="'Deletar ' + name " name="Delete" @ok="submit"
    okTitle="Deletar" okVariant="danger" cancelVariant="secondary"
    >
        <p>Tem certeza que deseja deletar {{ state.name }}?</p>
    </Modal>
</template>

<script>

import Modal from '@/components/static/Modal'
import View from '@/mixins/core/ViewBehavior'

export default {

  components: { Modal },
  mixins: [View],
  computed: {
    requestObj () {
      return this.state.requests[this.request]
    },
    dispatch () {
      return this.requestObj.dispatch
    }
  },
  methods: {
    submit () {
      if (this.dispatch) {
        this.$store.dispatch(this.module + '/' + this.dispatch,
          { self: this, request: this.requestObj, item: this.items[0] })
      }
      // this.$refs.modal.close()
      this.$router.push('/')
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    mainAttr: {
      type: String,
      default: 'name'
    },
    request: {
      type: String,
      default: 'delete'
    }
  }
}
</script>
